import { useEffect, useState } from "react";
import { Trash, ArchiveMinus, ArchiveSlash, Edit2 } from "iconsax-react";

const ViewCourses = ({ setToggle, setSelectedCourse }) => {
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    fetch("https://sore-blue-zebra-tutu.cyclic.app/getAllCourses")
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
      });
  }, [refetch]);

  useEffect(() => {
    setFilteredCourses(
      courses.filter((course) =>
        course.title.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, courses]);

  return (
    <>
      <div className="view-courses">
        <h2 className="text-center" style={{ marginBottom: "2rem" }}>
          Showing {filteredCourses.length} Courses
        </h2>
        <input
          type="text"
          placeholder="Search"
          style={{
            width: "100%",
            height: "50px",
            borderRadius: "10px",
            border: "none",
            outline: "none",
            padding: "0 1rem",
            fontSize: "1.5rem",
            marginBottom: "1rem",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          }}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />

        <div
          className="courses"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            overflowY: "scroll",
            marginTop: "1rem",
          }}
        >
          {filteredCourses.map((course) => (
            <div
              className="course"
              key={course.id}
              onClick={() => {}}
              style={{
                backgroundImage: `url(${course.image})`,

                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "300px",
                width: "300px",
                margin: "1rem",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "bold",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  transform: "scale(1.1)",
                },
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "300px",
                width: "300px",
                margin: "1rem",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "bold",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.5)",
                  height: "100%",
                  width: "100%",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                  transition: "all 0.3s ease-in-out",
                  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  alignContent: "center",
                  justifyItems: "center",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      width: "80%",
                    }}
                  >
                    {course.title}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "40%",
                      marginTop: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setSelectedCourse(course);
                        setToggle("add a course");
                      }}
                    >
                      <Edit2 size={25} color="#fff" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (course.archived === false) {
                          if (
                            window.confirm(
                              "Are you sure you want to archive this course?"
                            )
                          ) {
                            fetch(
                              `https://sore-blue-zebra-tutu.cyclic.app/archiveCourse/${course._id}`,
                              {
                                method: "POST",
                                body: JSON.stringify({
                                  id: course._id,
                                }),
                                headers: {
                                  "Content-type":
                                    "application/json; charset=UTF-8",
                                },
                              }
                            )
                              .then((res) => res.json())
                              .then((data) => {
                                if (data) {
                                  if (data["message"] === "Course not found")
                                    alert("Failed to archive course");
                                  else {
                                    setRefetch(!refetch);
                                    alert("Course archived successfully");
                                  }
                                } else {
                                  alert(
                                    "Something went wrong. Please try again"
                                  );
                                }
                              });
                          }
                        } else if (course.archived === true) {
                          if (
                            window.confirm(
                              "Are you sure you want to unarchive this course?"
                            )
                          ) {
                            fetch(
                              `https://sore-blue-zebra-tutu.cyclic.app/unarchiveCourse/${course._id}`,
                              {
                                method: "POST",
                                body: JSON.stringify({
                                  id: course._id,
                                }),
                                headers: {
                                  "Content-type":
                                    "application/json; charset=UTF-8",
                                },
                              }
                            )
                              .then((res) => res.json())
                              .then((data) => {
                                if (data) {
                                  if (data["message"] === "Course not found")
                                    alert("Failed to unarchive course");
                                  else {
                                    setRefetch(!refetch);
                                    alert("Course unarchived successfully");
                                  }
                                } else {
                                  alert(
                                    "Something went wrong. Please try again"
                                  );
                                }
                              });
                          }
                        }
                      }}
                    >
                      {course.archived === true ? (
                        <ArchiveSlash size={25} color="#fff" />
                      ) : (
                        <ArchiveMinus size={25} color="#fff" />
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this course from the system?"
                          )
                        ) {
                          fetch(
                            `https://sore-blue-zebra-tutu.cyclic.app/deleteCourse/${course._id}`,
                            {
                              method: "DELETE",
                              body: JSON.stringify({
                                id: course._id,
                              }),
                              headers: {
                                "Content-type":
                                  "application/json; charset=UTF-8",
                              },
                            }
                          )
                            .then((res) => res.json())
                            .then((data) => {
                              if (data) {
                                console.log(data);
                                if (data["message"] === "Course not found")
                                  alert("Failed to delete course");
                                else {
                                  setRefetch(!refetch);
                                  alert("Course deleted successfully");
                                }
                              } else {
                                alert("Something went wrong. Please try again");
                              }
                            });
                        }
                      }}
                    >
                      <Trash size={25} color="#fff" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ViewCourses;

import Titles from "../../Titles/Titles";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const AddCourse = ({ selectedCourse }) => {
  const [courseName, setCourseName] = useState("");
  const [courseImage, setCourseImage] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [isArabic, setIsArabic] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [selectedSectionType, setSelectedSectionType] = useState("text");
  const [course, setCourse] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [failedToSubmit, setFailedToSubmit] = useState(false);
  const [courseUploaded, setCourseUploaded] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("https://sore-blue-zebra-tutu.cyclic.app/getCategories")
      .then((res) => res.json())
      .then((data) => {
        let categories = data.map((category) => category.title);
        setCategories(categories);
        setCourseCategory(categories[0]);
      });
  }, []);

  const [courseCategory, setCourseCategory] = useState(categories[0]);

  useEffect(() => {
    if (submitted) {
      if (courseName && courseImage && courseCategory && chapters.length > 0) {
        setFailedToSubmit(false);
        if (selectedCourse) {
          const courseId = selectedCourse._id;
          fetch("https://sore-blue-zebra-tutu.cyclic.app/updateCourse", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ courseId, course }),
          })
            .then((res) => res.json())
            .then((data) => {
              setSubmitted(false);
              setCourseUploaded(true);
            });
        } else {
          fetch("https://sore-blue-zebra-tutu.cyclic.app/createCourse", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(course),
          })
            .then((res) => res.json())
            .then((data) => {
              setSubmitted(false);
              setCourseUploaded(true);
            });
        }
      } else {
        setFailedToSubmit(true);
        setSubmitted(false);
      }
    }
  }, [submitted]);

  useEffect(() => {
    if (selectedCourse) {
      setCourseName(selectedCourse.title);
      setCourseImage(selectedCourse.image);
      setIsFree(selectedCourse.free);
      setIsRecommended(selectedCourse.recommendedList);
      setIsArabic(selectedCourse.arabic);
      setIsArchived(selectedCourse.archived);
      setChapters(selectedCourse.content);
      setCourseCategory(selectedCourse.category);
    } else {
      setCourseName("");
      setCourseImage("");
      setIsFree(false);
      setIsRecommended(false);
      setIsArabic(false);
      setIsArchived(false);
      setChapters([]);
      setCourseCategory(categories[0]);
    }
  }, [selectedCourse, categories]);

  useEffect(() => {
    const course = {
      title: courseName,
      image: courseImage,
      category: courseCategory,
      free: isFree,
      recommendedList: isRecommended,
      content: chapters,
      arabic: isArabic,
      archived: isArchived,
    };
    setCourse(course);
  }, [
    courseName,
    courseImage,
    courseCategory,
    isFree,
    isRecommended,
    chapters,
    isArabic,
    isArchived,
  ]);

  const handleAddChapter = () => {
    setChapters([
      ...chapters,
      { title: "", contentType: "article", sections: [] },
    ]);
  };

  const handleRemoveChapter = (index) => {
    const newChapters = [...chapters];
    newChapters.splice(index, 1);
    setChapters(newChapters);
  };

  const handleChapterTitleChange = (index, title) => {
    const newChapters = [...chapters];
    newChapters[index].title = title;
    setChapters(newChapters);
  };

  const handleAddSection = (chapterIndex, sectionType) => {
    const newChapters = [...chapters];
    const newSections = [...newChapters[chapterIndex].sections];
    newSections.push({ type: sectionType, content: "" });
    newChapters[chapterIndex].sections = newSections;
    newChapters[chapterIndex].chapter = chapterIndex + 1;

    setChapters(newChapters);
  };

  const handleRemoveSection = (chapterIndex, sectionIndex) => {
    const newChapters = [...chapters];
    const newSections = [...newChapters[chapterIndex].sections];
    newSections.splice(sectionIndex, 1);
    newChapters[chapterIndex].sections = newSections;
    setChapters(newChapters);
  };

  const handleSectionContentChange = (chapterIndex, sectionIndex, content) => {
    const newChapters = [...chapters];
    newChapters[chapterIndex].sections[sectionIndex].content = content;
    setChapters(newChapters);
  };

  const handleSectionTypeChange = (chapterIndex, sectionIndex, type) => {
    const newChapters = [...chapters];
    newChapters[chapterIndex].sections[sectionIndex].type = type;
    setChapters(newChapters);
  };

  const handleSectionCaptionChange = (chapterIndex, sectionIndex, caption) => {
    const newChapters = [...chapters];
    newChapters[chapterIndex].sections[sectionIndex].caption = caption;
    setChapters(newChapters);
  };

  const handleChapterContentChange = (index, content) => {
    const newChapters = [...chapters];
    newChapters[index].content = content;
    setChapters(newChapters);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send the form data to the server
  };

  if (categories.length === 0) return <div>Loading...</div>;
  else
    return (
      <>
        <Titles
          title={selectedCourse ? "Edit course" : "Add a new course"}
          text={
            selectedCourse
              ? ""
              : "In this section, you can add a new course to the system by filling out the form below."
          }
        />
        <div
          style={{ height: "400px", overflowY: "scroll", marginTop: "1rem" }}
        >
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="courseName">Course Name</label>

              <input
                type="text"
                id="courseName"
                value={courseName}
                onChange={(event) => setCourseName(event.target.value)}
                style={{
                  width: "95%",
                  marginTop: "0.5rem",
                  paddingLeft: "0.5rem",
                  borderColor: failedToSubmit ? "red" : null,
                }}
              />
            </div>
            <div style={{ marginTop: "1rem" }}>
              <label htmlFor="courseImage">Course Image (URL)</label>
              <input
                type="text"
                id="courseImage"
                value={courseImage}
                onChange={(event) => setCourseImage(event.target.value)}
                style={{
                  width: "95%",
                  marginTop: "0.5rem",
                  paddingLeft: "0.5rem",
                  borderColor: failedToSubmit ? "red" : null,
                }}
              />
            </div>
            <div style={{ marginTop: "1rem" }}>
              <label htmlFor="courseCategory">Course Category</label>
              <select
                id="courseCategory"
                value={courseCategory}
                onChange={(event) => setCourseCategory(event.target.value)}
                style={{
                  width: "95%",
                  marginTop: "0.5rem",
                  paddingLeft: "0.5rem",
                  borderRadius: "0.25rem",
                }}
              >
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                marginTop: "2rem",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <label htmlFor="isFree">Free</label>
              <input
                type="checkbox"
                id="isFree"
                checked={isFree}
                onChange={(event) => setIsFree(event.target.checked)}
                style={{
                  marginLeft: "0.5rem",
                }}
              />
              <label style={{ marginLeft: 20 }} htmlFor="isRecommended">
                Recommended
              </label>
              <input
                type="checkbox"
                id="isRecommended"
                checked={isRecommended}
                onChange={(event) => setIsRecommended(event.target.checked)}
                style={{
                  marginLeft: "0.5rem",
                }}
              />
              <label style={{ marginLeft: 20 }} htmlFor="isArabic">
                Arabic
              </label>
              <input
                type="checkbox"
                id="isArabic"
                checked={isArabic}
                onChange={(event) => setIsArabic(event.target.checked)}
                style={{
                  marginLeft: "0.5rem",
                }}
              />

              <label style={{ marginLeft: 20 }} htmlFor="isArchived">
                Archived
              </label>
              <input
                type="checkbox"
                id="isArchived"
                checked={isArchived}
                onChange={(event) => setIsArchived(event.target.checked)}
                style={{
                  marginLeft: "0.5rem",
                }}
              />
            </div>
            <div
              style={{
                marginTop: "1rem",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {course.image && (
                <div
                  style={{
                    backgroundImage: `url(${course.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    margin: "1rem",
                    borderRadius: "10px",
                    color: "white",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    cursor: "pointer",
                    transition: "all 0.3s ease-in-out",
                    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                    height: "200px",
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "rgba(0, 0, 0, 0.5)",
                      height: "100%",
                      width: "100%",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      cursor: "pointer",
                      transition: "all 0.3s ease-in-out",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      alignContent: "center",
                      justifyItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <h4
                        style={{
                          width: "80%",
                          fontSize: "1rem",
                        }}
                      >
                        {course.title}
                      </h4>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ul
                style={{
                  width: "95%",
                  paddingLeft: "0.5rem",
                  listStyleType: "none",
                }}
              >
                {chapters.map((chapter, chapterIndex) => (
                  <li
                    key={chapterIndex}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "0.25rem",
                      padding: "0.5rem",
                      marginBottom: "0.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <button
                      style={{
                        borderRadius: "0.25rem",
                        padding: "0.25rem",
                        cursor: "pointer",
                        float: "right",
                        marginBottom: "1rem",
                      }}
                      onClick={() => handleRemoveChapter(chapterIndex)}
                    >
                      Remove Chapter
                    </button>

                    <div
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <label
                        htmlFor={`chapterTitle-${chapterIndex}`}
                        style={{
                          display: "block",
                          marginBottom: "0.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        Chapter {chapterIndex + 1}
                      </label>
                    </div>
                    <input
                      type="text"
                      id={`chapterTitle-${chapterIndex}`}
                      placeholder="Chapter Title"
                      value={chapter.title}
                      onChange={(event) =>
                        handleChapterTitleChange(
                          chapterIndex,
                          event.target.value
                        )
                      }
                      style={{
                        width: "90%",
                        paddingLeft: "0.5rem",
                        marginLeft: "2rem",
                        marginBottom: "2rem",
                      }}
                    />
                    <div
                      style={{
                        width: "70%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        //move to center
                        marginLeft: "auto",
                        marginRight: "auto",

                        borderTopWidth: "1px",
                        borderTopStyle: "solid",
                        borderTopColor: "#ccc",
                        paddingTop: "1rem",
                      }}
                    ></div>
                    <ul
                      style={{
                        listStyleType: "none",
                      }}
                    >
                      {chapter.sections.map((section, sectionIndex) => (
                        <li
                          key={sectionIndex}
                          style={{
                            marginBottom: "3rem",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "0.25rem",
                              padding: "0.25rem",
                              cursor: "pointer",
                              float: "right",
                              marginRight: "2rem",
                            }}
                            onClick={() =>
                              handleRemoveSection(chapterIndex, sectionIndex)
                            }
                          >
                            Remove Section
                          </button>
                          {section.type === "text" ? (
                            <>
                              <label
                                htmlFor={`sectionType-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Section {sectionIndex + 1}
                              </label>
                              <label
                                htmlFor={`sectionContent-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                }}
                              >
                                Paragraph
                              </label>
                              <textarea
                                type="text"
                                id={`sectionContent-${chapterIndex}-${sectionIndex}`}
                                value={section.content}
                                style={{
                                  width: "95%",
                                  paddingLeft: "0.5rem",
                                  height: "100px",
                                  verticalAlign: "top",
                                }}
                                onChange={(event) =>
                                  handleSectionContentChange(
                                    chapterIndex,
                                    sectionIndex,
                                    event.target.value
                                  )
                                }
                              />
                            </>
                          ) : section.type === "image" ? (
                            <>
                              <label
                                htmlFor={`sectionType-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Section {sectionIndex + 1}
                              </label>
                              <label
                                htmlFor={`sectionContent-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                }}
                              >
                                Image URL
                              </label>
                              <input
                                type="text"
                                id={`sectionContent-${chapterIndex}-${sectionIndex}`}
                                value={section.content}
                                style={{
                                  width: "95%",
                                  paddingLeft: "0.5rem",
                                }}
                                onChange={(event) =>
                                  handleSectionContentChange(
                                    chapterIndex,
                                    sectionIndex,
                                    event.target.value
                                  )
                                }
                              />

                              <label
                                htmlFor={`sectionCaption-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                  marginTop: "1rem",
                                }}
                              >
                                Image Caption
                              </label>
                              <input
                                type="text"
                                id={`sectionCaption-${chapterIndex}-${sectionIndex}`}
                                value={section.caption}
                                style={{
                                  width: "95%",
                                  paddingLeft: "0.5rem",
                                }}
                                onChange={(event) =>
                                  handleSectionCaptionChange(
                                    chapterIndex,
                                    sectionIndex,
                                    event.target.value
                                  )
                                }
                              />
                            </>
                          ) : section.type === "video" ? (
                            <>
                              <label
                                htmlFor={`sectionType-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Section {sectionIndex + 1}
                              </label>
                              <label
                                htmlFor={`sectionContent-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                }}
                              >
                                Video URL
                              </label>
                              <input
                                type="text"
                                id={`sectionContent-${chapterIndex}-${sectionIndex}`}
                                value={section.content}
                                style={{
                                  width: "95%",
                                  paddingLeft: "0.5rem",
                                }}
                                onChange={(event) =>
                                  handleSectionContentChange(
                                    chapterIndex,
                                    sectionIndex,
                                    event.target.value
                                  )
                                }
                              />
                            </>
                          ) : section.type === "subtitle" ? (
                            <>
                              <label
                                htmlFor={`sectionType-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Section {sectionIndex + 1}
                              </label>
                              <label
                                htmlFor={`sectionContent-${chapterIndex}-${sectionIndex}`}
                                style={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                }}
                              >
                                Subtitle
                              </label>
                              <input
                                type="text"
                                id={`sectionContent-${chapterIndex}-${sectionIndex}`}
                                value={section.content}
                                style={{
                                  width: "95%",
                                  paddingLeft: "0.5rem",
                                }}
                                onChange={(event) =>
                                  handleSectionContentChange(
                                    chapterIndex,
                                    sectionIndex,
                                    event.target.value
                                  )
                                }
                              />
                            </>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                    <div
                      style={{
                        paddingBottom: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "2rem",
                      }}
                    >
                      <div style={{}}>
                        <select
                          id={`sectionType-${chapterIndex}`}
                          onChange={(event) =>
                            setSelectedSectionType(event.target.value)
                          }
                          style={{
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                            borderRadius: "0.25rem",
                            borderWidth: "2px",
                            cursor: "pointer",
                          }}
                        >
                          <option value="text">Paragraph</option>
                          <option value="subtitle">Subtitle</option>
                          <option value="image">Image</option>
                          <option value="video">Video</option>
                        </select>
                      </div>
                      <button
                        onClick={() =>
                          handleAddSection(chapterIndex, selectedSectionType)
                        }
                        style={{
                          borderRadius: "0.25rem",
                          cursor: "pointer",
                        }}
                      >
                        Add Section
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <Button
                onClick={handleAddChapter}
                disabled={false}
                variant="primary"
                className="mt-5 py-2 px-4"
                type="submit"
              >
                Add Chapter
              </Button>
            </div>
          </form>
        </div>
        <Button
          onClick={() => {
            setSubmitted(true);
          }}
          disabled={false}
          variant="primary"
          className="mt-5 py-2 px-4"
          type="submit"
        >
          {selectedCourse ? "Update Course" : "Submit Course"}
        </Button>
        {failedToSubmit && (
          <div style={{ color: "red", marginTop: "1rem" }}>
            Failed to {selectedCourse ? "update" : "submit"} course. Please make
            sure all necessary fields are filled.
          </div>
        )}
        {courseUploaded && (
          <div style={{ color: "green", marginTop: "1rem" }}>
            {selectedCourse
              ? "Course successfully updated!"
              : "Course successfully uploaded!"}
          </div>
        )}
      </>
    );
};
export default AddCourse;

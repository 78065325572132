import { PureComponent } from "react";
import { createPortal } from "react-dom";

// import styles of this component
import styles from "./Panel.module.css";

// import other component
import UserCard from "./UserCard/UserCard";
import AddCourse from "./AddCourse/AddCourse";
import UserChangePassword from "./UserChangePassword/UserChangePassword";
import ViewCourses from "./ViewCourses/ViewCourses";

// import other pkgs
import { AddCircle, Lock, Archive, Code1 } from "iconsax-react";
import { Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";

// import utils
import { getStorage, updateStorage } from "./../../utils/storage";

class Panel extends PureComponent {
  constructor(props) {
    super(props);
    this.myVerifyUser = this.getUserFromStorage();

    this.state = {
      user: { ...this.initState(this.myVerifyUser) },
      toggle: "add a course",
      course: null,
    };

    this.sidebarLinks = [
      {
        id: 1,
        border: true,
        text: "Add a course",
        icon: <AddCircle size="20" color="black" />,
        active: true,
      },
      {
        id: 3,
        border: true,
        text: "View courses",
        icon: <Archive size="20" color="black" />,
        active: false,
      },
      {
        id: 2,
        border: true,
        text: "Password",
        icon: <Lock size="20" color="black" />,
        active: false,
      },
    ];

    this.logOut = this.logOut.bind(this);
    this.changeToggle = this.changeToggle.bind(this);
    this.changeAddCourse = this.changeAddCourse.bind(this);
  }

  getUserFromStorage() {
    const users = getStorage("users");
    const userId = getStorage("id");
    const myVerifyUser = users.find((user) => user.id === userId);

    return myVerifyUser;
  }

  initState({
    id,
    username,
    email,
    birthday,
    password,
    isLogin,
    firstName,
    lastName,
  }) {
    return {
      id,
      username,
      email,
      birthday,
      password,
      firstName,
      lastName,
      isLogin,
    };
  }

  logOut() {
    this.changeAddCourse(["isLogin"], [false]);
  }

  componentDidUpdate() {
    updateStorage(getStorage("users"), this.state.user);
    !this.state.user.isLogin && this.props.onLogOut();
  }

  changeToggle(toggle) {
    this.setState({ toggle });
  }

  setCourse = (newCourse) => {
    this.setState({ course: newCourse });
  };

  changeAddCourse(keyInfos, valInfos) {
    let newInfo = {};

    keyInfos.forEach((keyInfo, idx) => (newInfo[keyInfo] = valInfos[idx]));

    this.setState((prev) => {
      return {
        user: {
          ...prev.user,
          ...newInfo,
        },
      };
    });
  }

  render() {
    return (
      <div
        className={`${styles["panel-wrapper"]} d-flex align-items-center justify-content-center`}
      >
        {/* <div className={styles['bg-overlay']}></div> TODO */}
        <div
          className={`${styles.container} d-flex justify-content-center align-items-center p-0`}
        >
          <Row
            className={`${styles["panel"]} flex-column flex-md-row justify-content-center align-items-center px-3`}
          >
            <Col
              xs={12}
              sm={8}
              md={4}
              className="d-flex flex-column justify-content-center p-0"
            >
              <UserCard
                username={this.state.user.username}
                userBirthday={this.state.user.birthday}
                userEmail={this.state.user.email}
                sidebarLinks={this.sidebarLinks}
                onChangeToggle={this.changeToggle}
                setSelectedCourse={this.setCourse}
              />
            </Col>

            <Col
              xs={12}
              sm={8}
              md={7}
              className={`${styles["panel-column"]} bg-white border mt-5 mt-md-0 ms-md-5 p-5`}
            >
              {this.state.toggle === "add a course" && (
                <AddCourse
                  username={this.state.user.username}
                  firstName={this.state.user.firstName}
                  lastName={this.state.user.lastName}
                  email={this.state.user.email}
                  birthday={this.state.user.birthday}
                  onChangeInfo={this.changeAddCourse}
                  selectedCourse={this.state.course}
                  setSelectedCourse={this.setCourse}
                />
              )}
              {this.state.toggle === "view courses" && (
                <ViewCourses
                  setToggle={this.changeToggle}
                  setSelectedCourse={this.setCourse}
                />
              )}
              {this.state.toggle === "password" && (
                <UserChangePassword
                  username={this.state.user.username}
                  password={this.state.user.password}
                  onChangeInfo={this.changeAddCourse}
                />
              )}
            </Col>
          </Row>
        </div>
        {createPortal(
          <Button
            variant="primary"
            className={styles["log-out-btn"]}
            onClick={this.logOut}
          >
            Log out
          </Button>,
          document.getElementById("root")
        )}
      </div>
    );
  }
}

// validate component
Panel.propTypes = {
  onLogOut: PropTypes.func.isRequired,
};

export default Panel;

import { useState } from "react";

// import styles of this component
import styles from "../Forms.module.css";

// import other component
import FormInput from "../FormInput/FormInput";

import { v4 as uniqid } from "uuid";

// import other pkgs
import { Container, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { object, string } from "yup";
import PropTypes from "prop-types";

// import utils
import {
  getStorage,
  setUserId,
  updateStorage,
  setUserInStorage,
} from "../../../utils/storage";

const LoginForm = ({ onRegister, onLogin }) => {
  const [submit, setSubmit] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    validationSchema: object({
      username: string()
        .required("please enter your username")
        .max(15, "your username must be 15 characters or less")
        .min(4, "your username must be 4 characters or more"),
      password: string()
        .required("please enter your password")
        .min(8, "your password must be 8 characters or more")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
          "invalid password"
        ),
    }),
    onSubmit: ({ username, email, password }, { setFieldError }) => {
      const users = getStorage("users");
      const myVerifyUser =
        users && users.find((user) => user.username === username);

      fetch("https://sore-blue-zebra-tutu.cyclic.app/verifyAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data === true) {
            const userId = uniqid();
            const users = [{ id: userId, username, password, isLogin: true }];
            setUserId(userId);
            setUserInStorage("users", users);
            login({ id: userId, username, password, isLogin: true });
            console.log("yest");
          } else {
            alert("username or password is incorrect");
          }
        });

      //   if (users && myVerifyUser) {
      //     if (
      //       myVerifyUser.email === email &&
      //       myVerifyUser.password === password
      //     ) {
      //       login(myVerifyUser);
      //     } else if (myVerifyUser.email !== email)
      //       setFieldError("email", `your email isn't correct`);
      //     else setFieldError("password", `your password isn't correct`);
      //   } else setFieldError("username", "your username was not found");
    },
  });

  const login = (myVerifyUser) => {
    const users = getStorage("users");
    updateStorage(users, myVerifyUser, true);
    setUserId(myVerifyUser.id);
    onLogin();
  };

  return (
    <Container
      fluid
      className={`${styles.container} d-flex justify-content-center align-items-center px-5`}
    >
      <Form noValidate className={styles.form} onSubmit={formik.handleSubmit}>
        <h2>Login</h2>

        <FormInput
          className="mb-4 mt-5"
          name="username"
          controlId="username-input"
          text="Username"
          placeholder="Enter your Username"
          errMsg={formik.errors.username || ""}
          successMsg=""
          invalid={submit && formik.errors.username ? true : false}
          {...formik.getFieldProps("username")}
        />

        <FormInput
          className="mb-4"
          name="password"
          controlId="password-input"
          text="Password"
          placeholder="Enter your Password"
          type="password"
          errMsg={formik.errors.password || ""}
          successMsg=""
          invalid={submit && formik.errors.password ? true : false}
          {...formik.getFieldProps("password")}
        />

        <Button
          className={`${styles["submit-btn"]} w-100`}
          onClick={() => setSubmit(true)}
          disabled={submit && !formik.isValid ? true : false}
          variant="primary"
          type="submit"
        >
          Login
        </Button>
      </Form>
    </Container>
  );
};

// validate the component
LoginForm.propTypes = {
  onRegister: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default LoginForm;
